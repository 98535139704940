<template>
  <b-card>

    <b-modal
      id="fintech-service-modal"
      :title="`${updateId ? 'Update' : 'Add'} Payment Service`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleFintechServiceFormSubmit"
      @cancel="resetFintechService"
      @close="resetFintechService"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Service Category"
              label-cols-md="4"
              invalid-feedback="Service Category is required"
              :state="serviceCategoryState"
            >
              <v-select
                v-model="fintechServiceFormData.serviceCategoryId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Service Category"
                :options="fintechServiceCategoryOptions"
                :reduce="val => val.id"
                :state="serviceCategoryState"
              />
            </b-form-group>
          </b-col>
          <!-- VAT Percentage -->
          <b-col cols="12">
            <b-form-group
              label="Service Name"
              label-cols-md="4"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <b-form-input
                v-model="fintechServiceFormData.name"
                placeholder="Enter Service Name"
                :state="nameState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-cols-md="4"
              invalid-feedback="Status is required"
              :state="statusState"
            >
              <b-form-radio-group
                v-model="fintechServiceFormData.status"
                :options="fintechServiceStatusOptions"
                name="radio-inline"
                :state="statusState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Payment Service
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add Payment Service
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-if="props.column.field === 'status'">
          <!--          {{ props.formattedRow[props.column.field] == 1 ? 'Active' : 'Inactive' }}-->
          <span class="d-flex">
            <p v-if="props.row.status === 1">Active</p>
            <p v-else>Inactive</p>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BPagination, BButton, BFormRadioGroup, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

import ActivityModal from '../audit/ActivityModal.vue'
import PaymentService from '@/@service/api/accounting/fintech/Payment.service'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BFormRadioGroup,
    RefreshCwIcon,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Category',
          field: 'service_category.name',
        },
        {
          label: 'Service',
          field: 'name',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      fintechServiceFormData: {
        serviceCategoryId: null,
        name: null,
        status: null,
      },
      campusOptions: [],
      classOptions: [],
      fintechServiceCategoryOptions: [],
      fintechServiceStatusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],

      serviceCategoryState: null,
      nameState: null,
      statusState: null,

      // Activity Log Data
      tag: 'acc-fintech-service',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getFintechServiceCategories()
    this.getFintechServiceGridData()
  },
  methods: {
    getFintechServiceCategories() {
      PaymentService.serviceCategory().then(res => {
        this.fintechServiceCategoryOptions = res.data.data
      })
    },
    handleFintechServiceFormSubmit() {
      if (this.updateId) {
        this.updateFintechService()
      } else {
        this.saveFintechService()
      }
    },
    validation() {
      this.serviceCategoryState = null
      this.nameState = null
      this.statusState = null
      if (!this.fintechServiceFormData.serviceCategoryId) {
        this.serviceCategoryState = false
        return false
      }
      if (!this.fintechServiceFormData.name) {
        this.nameState = false
        return false
      }
      if (this.fintechServiceFormData.status === null || this.fintechServiceFormData.status === '') {
        this.statusState = false
        return false
      }
      return true
    },
    saveFintechService() {
      if (!this.validation()) {
        return
      }
      PaymentService.save(this.fintechServiceFormData).then(res => {
        if (res.data.status) {
          this.resetFintechService()
          this.getFintechServiceGridData()
          this.$bvModal.hide('fintech-service-modal')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    updateFintechService() {
      if (!this.validation()) {
        return
      }
      PaymentService.update(this.updateId, this.fintechServiceFormData).then(res => {
        if (res.data.status) {
          this.resetFintechService()
          this.getFintechServiceGridData()
          this.$bvModal.hide('fintech-service-modal')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getFintechServiceGridData() {
      PaymentService.getAll().then(res => {
        if (res.data.status) {
          this.rows = res.data.data
        } else {
          this.rows = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    refreshGrid() {
      this.getFintechServiceGridData()
    },
    resetFintechService() {
      this.updateId = null
      this.fintechServiceFormData = {
        serviceCategoryId: null,
        name: null,
        status: null,
      }

      this.serviceCategoryState = null
      this.nameState = null
      this.statusState = null
    },
    openModal() {
      this.resetFintechService()
      this.$bvModal.show('fintech-service-modal')
    },
    editRow(rowData) {
      this.updateId = rowData.id
      this.fintechServiceFormData = {
        id: rowData.id,
        serviceCategoryId: rowData.service_category_id,
        name: rowData.name,
        status: rowData.status,
      }

      this.$bvModal.show('fintech-service-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PaymentService.delete(rowData.id).then(res => {
            if (res.status && res.data.status) {
              this.resetFintechService()
              this.getFintechServiceGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res.data.message)
            }
          })
        }
      })
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
